import { createActionGroup, props } from '@ngrx/store';
import { UserModel, UserRequestModel } from '../../_models/user.model';
import { PersonModel, PersonRequestModel } from '../../_models/person.model';

export const AuthActions = createActionGroup({
  source: 'Auth',
  events: {
    'Login Auth': props<{ username: string; password: string }>(),
    'Login Auth Success': props<{ token: string; status: boolean }>(),
    'Load User Auth': props<any>(),
    'Load User Auth Success': props<{ user: UserModel }>(),
    'Logout Auth': props<any>(),
    'Register Auth': props<{ data: Readonly<UserRequestModel> }>(),
    'Edit User Auth': props<{ data: Readonly<PersonRequestModel> }>(),
    'Edit User Auth Success': props<{ data: Readonly<PersonRequestModel> }>(),
    'Forgot Auth': props<{ email: string }>(),
    'Forgot Auth Success': props<any>(),
    'Reset Password Auth': props<{ hash: string; password: string }>()
  },
});
